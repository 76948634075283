import React, {createContext, useContext} from 'react';
import {useLocalStorage} from "usehooks-ts";

const emptyCustomer = {
    token: '',
    name: '',
    has_extra_permission: false,
    has_custom_pricing: false,
    intended_store_view: '',
    email: '',
    is_admin_mode: false,
    is_remote_mode: false,
    admin_mode: {},
    has_multiple_personas: false,
    active_persona: null,
    initial_persona: null,
    persona_banner_dismissed: false,
    sso_user: null,
    expiry: null,
    login_failed_at: null
};

const CustomerContext = createContext({});

export function CustomerContextProvider(props) {

    const [customer, setCustomer] = useLocalStorage('ont_customer', emptyCustomer);

    const resetCustomer = () => setCustomer(emptyCustomer);

    return (
        <CustomerContext.Provider value={{customer, setCustomer, resetCustomer}}>
            {props.children}
        </CustomerContext.Provider>
    );
}

export const useCustomerContext = () => useContext(CustomerContext);

// hooks can only be used inside a component, so this allows direct access
export const getStoredCustomer = () => {
    if (typeof window === "undefined") {return {};}

    const customerJson = localStorage.getItem('ont_customer');
    return customerJson ? JSON.parse(customerJson) : {};
}

export const isExpired = customer => {
    const expiry = customer.expiry;
    if (expiry) {
        const expiryDate = new Date(expiry);
        const today = new Date();
        return (expiryDate <= today);
    }
    return false;
}

export const hasLoginFailedInLastHour = customer => {
    if (!customer.login_failed_at) return false;

    const previous = new Date(customer.login_failed_at);
    const current = new Date();
    const minutes = (current - previous) / 1000 / 60;

    return Math.round(minutes) < 60;
}

export const setLoginFailed = setCustomer => {
    setCustomer({...emptyCustomer, login_failed_at: new Date().toISOString()});
}

