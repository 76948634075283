import React, { useState } from 'react';
import useLocalStorage from "../../../utils/useLocalStorage";
import useGenerateCustomerTokenFromSsoCode from './useGenerateCustomerTokenFromSsoCode';
import useLogin, { resetCredentials } from '../hooks/useLogin';
import LoggingIn from '../Status/loggingIn';
import TokenError from '../Status/tokenError';
import CartError from '../Status/cartError';
import { getRedirectUrl } from '../helpers';

export default function Standard({code}) {
    const [authDestPath] = useLocalStorage('ont_auth_dest_path');
    const [customerTokenMutation, {called: customerTokenMutationCalled, data: customerTokenData, error: customerTokenError}] = useGenerateCustomerTokenFromSsoCode();
    const [cartError, setCartError] = useState({});
    const redirectUrl = getRedirectUrl(authDestPath);
    const newToken = customerTokenData?.generateCustomerTokenFromSsoCode?.token;
    const performLogin = useLogin(redirectUrl, setCartError, newToken);

    resetCredentials(!customerTokenMutationCalled);
    if (!customerTokenMutationCalled) {
        customerTokenMutation({variables: {ssoCode: code}})
            .then(data => performLogin(data.data.generateCustomerTokenFromSsoCode, {}))
            .catch(err => { /* will be handled by TokenError below */});
    }

    if (customerTokenError) {
        return <TokenError errorObject={customerTokenError} />
    }

    if (cartError && Object.keys(cartError).length > 0) {
        return <CartError errorObject={cartError} />
    }

    return <LoggingIn />;
}

