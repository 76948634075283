import React from 'react';
import useLoginLink from '../../../hooks/useLoginLink';
import { isProduction } from '../../../hooks/useEnvironment';
import { useCustomerContext, hasLoginFailedInLastHour, setLoginFailed } from '../../../contexts/CustomerContext';

export default function TokenError({errorObject}) {
    const { customer, setCustomer } = useCustomerContext();

    if (!hasLoginFailedInLastHour(customer)) {
        setLoginFailed(setCustomer);
    }

    return (
        <div style={{margin: '4rem'}}>
            <h2 style={{fontWeight: 'lighter'}}>Sorry, we could not log you in...</h2>
            <ErrorMessage errorObject={errorObject} />
        </div>
    );
}

function ErrorMessage({errorObject}) {
    switch (errorObject?.message) {
        case 'Login failed':
            return <LoginFailed />;
        case 'No store access':
            return <NoStoreAccess />;
        case 'Account configuration error':
        case 'Login customer does not exist':
            return <ConfigurationError />;
    }

    return (
        <>
            <code>{errorObject?.message}</code><br />
            {errorObject?.graphQLErrors?.length && <code>{errorObject?.graphQLErrors[0].debugMessage}</code>}
        </>
    );
}

function LoginFailed() {
    const loginLink = useLoginLink();

    const registrationLink = isProduction()
        ? "https://registration.nanoporetech.com/account"
        : "https://registration-test.dig-dev.nanoporetech.com/account";

    return (
        <>
            <p>We could not authenticate your credentials with our single-sign on service.</p>
            <p>Please <a href={loginLink}>click here</a> to return to the login page.</p>
            <p>If you have not yet registered for store access, please visit our <a href={registrationLink}>Registration page</a>.</p>
        </>
    );
}

function NoStoreAccess() {
    return (
        <>
            <p>No store access.</p>
            <p>Your login was successful. However, your user account is not set up for buying from our store. If you feel this is incorrect please <a href="mailto:Customer.Support@nanoporetech.com">contact our customer service team</a>.</p>
        </>
    );
}

function ConfigurationError() {
    return (
        <>
            <p>Configuration error.</p>
            <p>Your login was successful. However, your shop account seems to be misconfigured. Please <a href="mailto:Customer.Support@nanoporetech.com">contact our customer service team</a>.</p>
        </>
    );
}
